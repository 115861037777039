
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #F8F8F8;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.active {
  color: #DB9A48;
}

.hero {
  background-image: url('./assets/hero_pathway.webp'); 
  background-size: cover;
  background-position: center;
  height: 80vh; 
}


.mySwiper {
  height: 100vh; 
}

.mySwiper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.pulse-button {
  cursor: pointer;  
  animation: pulse 1s infinite;
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.blink-text {
  animation: blink 1.5s infinite;
}

.menu {
  animation: menu 0.5s both;
}


@keyframes menu {
  0% {
    transform: translateY(-2%);
  }
  100% {
    transform: translateY(0);
  }
}


/* color: #DB9A48 */